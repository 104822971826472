import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import './Header.css'; // Import the CSS file for any custom styles

const Header = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const getButtonVariant = (language) => {
        return i18n.language === language ? 'light' : 'outline-light';
    };

    return (
        <header>
            <div className="bg-success text-white py-2">
                <Container>
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <span className="text-uppercase phoneNumber">
                            <span>{t('header.call_us')}</span>
                            <span className="ml-2">{t('header.phone')} <i className="fa fa-phone"></i></span>
                        </span>
                        <div className="mt-2 mt-md-0">
                            <Button
                                variant={getButtonVariant('en')}
                                onClick={() => changeLanguage('en')}
                                className="mr-2 btn-sm btni18n"
                            >
                                EN
                            </Button>
                            <Button
                                variant={getButtonVariant('es')}
                                onClick={() => changeLanguage('es')}
                                className="btn-sm btni18n"
                            >
                                ES
                            </Button>
                        </div>
                    </div>
                </Container>
            </div>
            <Navbar bg="white" expand="lg" sticky="top" className='navbarSmallScreen'>
                <Container>
                    <Navbar.Brand href="#">
                        <img src="assets/images/logo.svg" width="auto" height="30" className='logoSamall' alt="LR Cleaning Service logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbar-nav" />
                    <Navbar.Collapse id="navbar-nav">
                        <Nav className="ml-auto align-items-center">
                            <Nav.Link href="#about">{t('header.about')}</Nav.Link>
                            <Nav.Link href="#services">{t('header.services')}</Nav.Link>
                            <Nav.Link href="#why-choose-us">{t('header.why_choose_us')}</Nav.Link>
                            <Nav.Link href="#testimonials">{t('header.testimonials')}</Nav.Link>
                            <Nav.Link href="#contacts">{t('header.contacts')}</Nav.Link>
                            <Nav.Link href="https://www.facebook.com/people/LR-Cleaning-Service-LLC/61557246436629/">
                                <i className="fab fa-facebook-f"></i>
                            </Nav.Link>
                            <Nav.Link href="https://tiktok.com/@linaresriverajoseline?lang=es">
                                <i className="fab fa-tiktok"></i>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
