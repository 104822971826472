import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import './Services.css'; // Import the CSS file

const Services = () => {
    const { t } = useTranslation();

    const services = [
        {
            title: t('services.home_cleaning.title'),
            description: t('services.home_cleaning.description'),
            image: 'assets/images/image-53.jpg',
        },
        {
            title: t('services.apartment_cleaning.title'),
            description: t('services.apartment_cleaning.description'),
            image: 'assets/images/image-19.jpg',
        },
        {
            title: t('services.move_in_out_cleaning.title'),
            description: t('services.move_in_out_cleaning.description'),
            image: 'assets/images/image-22.jpg',
        },
        {
            title: t('services.deep_cleaning.title'),
            description: t('services.deep_cleaning.description'),
            image: 'assets/images/image-23.jpg',
        },
        {
            title: t('services.office_cleaning.title'),
            description: t('services.office_cleaning.description'),
            image: 'assets/images/image-21.jpg',
        }
    ];

    return (
        <section className="py-5 my-5 bg-secondary" id="services">
            <Container className='mt-5 mb-5'>
                <div className="text-center">
                    <h1>{t('services.title')}</h1>
                </div>
                <Row className="mt-4">
                    {services.map((service, index) => {
                        // Determine the offset for the last two items
                        const isLastRow = index >= services.length - 2;
                        const offsetClass = isLastRow && index % 3 === 0 ? 'offset-md-2 offset-lg-2' : '';

                        return (
                            <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className={`mb-4 ${offsetClass}`}
                                key={index}
                            >
                                <div className="service-grid">
                                    <div className="service-img-container">
                                        <img src={service.image} alt={service.title} className="img-fluid" />
                                    </div>
                                    <div className="service-info">
                                        <h2>{service.title}</h2>
                                        <p>{service.description}</p>
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </Container>
        </section>
    );
};

export default Services;
