import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

const About = () => {
    const { t } = useTranslation();

    return (
        <section className="py-5 my-5" id="about">
            <Container className="text-center mt-5 mb-5">
                <h1 className="mb-3">{t('about.title')}</h1>
                <small className="fs-1 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
                    {t('about.subtitle')}
                </small>
                <Row className="mt-2">
                    <Col lg={{ span: 10, offset: 1 }}>
                        <p>{t('about.description')}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
