import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import './Footer.css'; // Import the CSS file for any custom styles

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="bg-secondary text-white py-5 my-5">
            <Container className='mt-5 mb-5'>
                <Row className="align-items-center">
                    <Col md={12} className="text-center mb-3 mt-5">
                        <img src="assets/images/logo.svg" width="auto" height="40" alt="LR Cleaning Service logo" />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="text-center">
                        <p>&copy; {new Date().getFullYear()} Linares Rivera Cleaning Service. {t('footer.copyright')}</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
