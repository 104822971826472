import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import './ContactForm.css'; // Import the CSS file for any custom styles

const ContactForm = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        $firstName: '',
        $lastName: '',
        phone: '',
        email: '',
        $service: '',
        $zipCode: '',
        message: '',
        honeypot: '',
        replyTo: '@', // This will set replyTo of email to email address entered in the form
        accessKey: '6e3c6b75-1e76-4edf-8612-dcb3adc67cc5' // Get your access key from https://www.staticforms.xyz
    });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [response, setResponse] = useState({
        type: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch('https://api.staticforms.xyz/submit', {
                method: 'POST',
                body: JSON.stringify(formData),
                headers: { 'Content-Type': 'application/json' }
            });

            const json = await res.json();

            if (json.success) {
                setResponse({
                    type: 'success',
                    message: 'Thank you for reaching out to us.'
                });
                setShowConfirmation(true);
                setFormData({
                    $firstName: '',
                    $lastName: '',
                    phone: '',
                    email: '',
                    $service: '',
                    $zipCode: '',
                    message: '',
                    honeypot: '',
                    replyTo: '@',
                    accessKey: '6e3c6b75-1e76-4edf-8612-dcb3adc67cc5'
                });
            } else {
                setResponse({
                    type: 'error',
                    message: json.message
                });
            }
        } catch (e) {
            console.log('An error occurred', e);
            setResponse({
                type: 'error',
                message: 'An error occurred while submitting the form'
            });
        }
    };

    return (
        <section className="py-5 my-5" id="contacts">
            <Container className='mt-5 mb-5'>
                <div className="text-center">
                    {/* <small className="fs-1 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
                        {t('contact.description')}
                    </small> */}
                    <h1>{t('contact.title')}</h1>
                </div>
                <Row className="mt-4">
                    <Col lg={{ span: 8, offset: 2 }}>
                        {response.message && (
                            <Alert
                                variant={response.type === 'success' ? 'success' : 'danger'}
                                onClose={() => setResponse({ type: '', message: '' })}
                                dismissible
                            >
                                {response.message}
                            </Alert>
                        )}
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group controlId="$firstName">
                                        <Form.Label>{t('contact.form.first_name')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="$firstName"
                                            value={formData.$firstName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="$lastName">
                                        <Form.Label>{t('contact.form.last_name')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="$lastName"
                                            value={formData.$lastName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="phone">
                                        <Form.Label>{t('contact.form.phone')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="email">
                                        <Form.Label>{t('contact.form.email')}</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="$service">
                                        <Form.Label>{t('contact.form.service')}</Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="$service"
                                            value={formData.$service}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">{t('contact.form.service')}</option>
                                            <option value="Home Cleaning">{t('services.home_cleaning.title')}</option>
                                            <option value="Apartment Cleaning">{t('services.apartment_cleaning.title')}</option>
                                            <option value="Deep Cleaning">{t('services.deep_cleaning.title')}</option>
                                            <option value="Move in/out Cleaning">{t('services.move_in_out_cleaning.title')}</option>
                                            <option value="Office Cleaning">{t('services.office_cleaning.title')}</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="$zipCode">
                                        <Form.Label>{t('contact.form.zip_code')}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="$zipCode"
                                            value={formData.$zipCode}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12}>
                                    <Form.Group controlId="message">
                                        <Form.Label>{t('contact.form.message')}</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange}
                                            rows={3}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                {/* Honeypot field to prevent bot submissions */}
                                <Col sm={12} style={{ display: 'none' }}>
                                    <Form.Group controlId="honeypot">
                                        <Form.Label>Honeypot</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="honeypot"
                                            value={formData.honeypot}
                                            onChange={handleChange}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} className="text-right mt-5">
                                    <Button variant="success" type="submit" size="lg">
                                        {t('contact.form.submit')}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ContactForm;
