import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './WhyChooseUs.css'; // Import the CSS file for any custom styles

const WhyChooseUs = () => {
    const { t } = useTranslation();

    const reasons = [
        t('why_choose_us.reasons.experience'),
        t('why_choose_us.reasons.flexible_scheduling'),
        t('why_choose_us.reasons.professional_staff'),
        t('why_choose_us.reasons.high_quality_products'),
        t('why_choose_us.reasons.customer_service'),
    ];

    return (
        <section className="py-5 my-5" id="why-choose-us">
            <Container className='mt-5 mb-5'>
                <div className="text-center">
                    {/* <small className="fs-1 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
                        {t('why_choose_us.subtitle')}
                    </small> */}
                    <h1>{t('why_choose_us.title')}</h1>
                </div>
                <Row className="mt-4">
                    {reasons.map((reason, index) => {
                        // Determine the offset for the last two items
                        const isLastRow = index >= reasons.length - 2;
                        const offsetClass = isLastRow && index % 3 === 0 ? 'offset-md-2 offset-lg-2' : '';

                        return (
                            <Col
                                md={6}
                                lg={4}
                                className={`mb-4 ${offsetClass}`}
                                key={index}
                            >
                                <Card className="h-100 text-center no-border-card">
                                    <Card.Body>
                                        <i className="material-icons mb-3" style={{ fontSize: '40px', color: 'green' }}>check_circle</i>
                                        <Card.Text>{reason}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
                <div className="text-center mt-4">
                    <Button href="tel:+19292618714" variant="success" size="lg">{t('why_choose_us.call_to_action')}</Button>
                </div>
            </Container>
        </section>
    );
};

export default WhyChooseUs;
