import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Carousel, Card } from 'react-bootstrap';
import './Testimonials.css'; // Import the CSS file for any custom styles

const Testimonials = () => {
    const { t } = useTranslation();

    const testimonials = [
        {
            name: 'Megan Skubal',
            service: t('testimonials.reviews.megan.service'),
            review: t('testimonials.reviews.megan.review'),
            rating: 5,
            image: 'assets/images/fb.svg',
        },
        {
            name: 'Aparna Ahuja',
            service: t('testimonials.reviews.aparna.service'),
            review: t('testimonials.reviews.aparna.review'),
            rating: 5,
            image: 'assets/images/fb.svg',
        },
        {
            name: 'Christina Sanchez Ambriz',
            service: t('testimonials.reviews.christina.service'),
            review: t('testimonials.reviews.christina.review'),
            rating: 5,
            image: 'assets/images/fb.svg',
        },
        {
            name: 'Jillian Harvell',
            service: t('testimonials.reviews.jillian.service'),
            review: t('testimonials.reviews.jillian.review'),
            rating: 5,
            image: 'assets/images/fb.svg',
        },
        {
            name: 'Erica Pratt Ray',
            service: t('testimonials.reviews.erica.service'),
            review: t('testimonials.reviews.erica.review'),
            rating: 5,
            image: 'assets/images/fb.svg',
        },
    ];

    return (
        <section className="py-5 my-5 bg-secondary" id="testimonials">
            <Container className='mt-5 mb-5'>
                <div className="text-center m-4">
                    {/* <small className="fs-1 font-family-secondary text-uppercase font-weight-bold letter-spacing-caption text-muted">
                        {t('testimonials.subtitle')}
                    </small> */}
                    <h1>{t('testimonials.title')}</h1>
                </div>
                <Carousel className="mt-4">
                    {testimonials.map((testimonial, index) => (
                        <Carousel.Item key={index}>
                            <Card className="bg-light text-center mb-5 p-5">
                                <Card.Body>
                                    <img
                                        src={testimonial.image}
                                        alt="Facebook icon"
                                        width={45}
                                        className="rounded-circle mb-3"
                                    />
                                    <Card.Title>{testimonial.name}</Card.Title>
                                    <Card.Subtitle className="mb-3 text-muted">{testimonial.service}</Card.Subtitle>
                                    <Card.Text>"{testimonial.review}"</Card.Text>
                                    <div className="star-rating">
                                        {[...Array(5)].map((star, i) => (
                                            <i
                                                key={i}
                                                className="material-icons"
                                                style={{ color: i < Math.floor(testimonial.rating) ? '#ffc107' : '#e4e5e9' }}
                                            >
                                                star
                                            </i>
                                        ))}
                                        {testimonial.rating % 1 !== 0 && (
                                            <i className="material-icons" style={{ color: '#ffc107' }}>
                                                star_half
                                            </i>
                                        )}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        </section>
    );
};

export default Testimonials;
