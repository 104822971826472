import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import heroImage from '../assets/images/image-17.jpg'; // Adjust the path as necessary

const Hero = () => {
    const { t } = useTranslation();

    return (
        <section className="hero hero-fullscreen" style={{ position: 'relative', height: '100vh' }}>
            <div style={{ position: 'relative', overflow: 'hidden', height: '100%' }}>
                <img src={heroImage} alt="hero background" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                <div style={{ backgroundColor: 'rgba(0,0,0,.75)', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>
            </div>
            <Container className="d-flex flex-column justify-content-center align-items-center text-center text-white" style={{ position: 'absolute', top: 0, left: 0, right: 0, width: '100%', height: '100%' }}>
                <h1 className="display-4 font-weight-bold mt-4 heroSmallScreen">{t('hero.title')}</h1>
                <p className="lead mt-4 mb-5 text-white heroSmallScreen2" style={{ maxWidth: '530px' }}>{t('hero.description')}</p>
                <Button href="tel:+19292618714" variant="success" size="lg">{t('hero.call_to_action')}</Button>
                <Row className="mt-5 w-100 iconsSmallScreen">
                    <Col md={4} className="mb-4">
                        <Card className="bg-transparent border-0 text-center text-white">
                            <Card.Body>
                                <i className="material-icons mb-3" style={{ fontSize: '40px' }}>location_on</i>
                                <Card.Title className="text-uppercase font-weight-bold">{t('hero.service_area')}</Card.Title>
                                <Card.Text>{t('hero.service_area_location')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="bg-transparent border-0 text-center text-white">
                            <Card.Body>
                                <i className="material-icons mb-3" style={{ fontSize: '40px' }}>phone</i>
                                <Card.Title className="text-uppercase font-weight-bold">{t('hero.contact_phone')}</Card.Title>
                                <Card.Text>(929) 261-8714</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={4} className="mb-4">
                        <Card className="bg-transparent border-0 text-center text-white">
                            <Card.Body>
                                <i className="material-icons mb-3" style={{ fontSize: '40px' }}>star</i>
                                <Card.Title className="text-uppercase font-weight-bold">100%</Card.Title>
                                <Card.Text>{t('hero.recommend')}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Hero;